import { axios, API_ROUTES } from "@/config";

export default {
  /**
   * consult the api of farm similary by id
   * @param {string} params - Gets the hash SHA-256.
   * @param {number} id - Gets the id of the farm.
   */
  async getAll(params, id) {
    let result = await axios.get(`${API_ROUTES.similaryFarm.get}/${id}`, {
      params: params,
    });

    return result;
  },
};
